import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import Layout from "../components/Layout"
import BackImage from "../images/backgroundBlack.png"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import SEO from "../components/SEO"


const StyledWrap = styled.div`
  width:100%;
  position:relative;
`
const StyledBack = styled.div`
  position:absolute;
  background:url(${BackImage});
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:0;
`
const StyledContents = styled.div`
    ${() => media.tablet(css`
      max-width:800px;
    `
)}
  z-index:1;
  position:relative;
  padding:30px 0;
  width:90%;
  margin:0 auto;
  .pageTitle{
    padding-bottom:50px;
        ${() => media.tablet(css`
      padding-bottom:100px;
    `
)}

    h1{
      width:100%;
      text-align:center;
      font-size:1.62em;
    }
    p{
      padding:1.62em 0 0;
    }
  }
`
const StyledBlock = styled.div`
  display:flex;
  flex-flow:row wrap;
  align-items:flex-start;
  justify-content:space-between;
  position:relative;
  margin-bottom:60px;
  .blockSec{
    width:58%;
    h2{
      padding-bottom:1.32em;
      font-size:1.12em;
      font-weight:bold;
    }
    p{
      font-size:.9em;
    }
  }
  .gatsby-image-wrapper{
    width:40%;
  }
`


const ForFirst = () => {
  const data = useStaticQuery(query)
  const jsonld = [
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name": `${data.seo.pagesSeo[1].pageTitle}`,
      "description": `${data.seo.pagesSeo[1].pageDescript}`,
      "publisher": {
        "@type": "ProfilePage",
        "name": `${data.seo.organizeName}`
      }
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.pagesSeo[0].pageUrl}`,
          "name": `${data.seo.pagesSeo[0].pageTitle}`,
          "image": `${data.seo.pagesSeo[0].pageMedia.absolutePath}`
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.pagesSeo[1].pageUrl}`,
          "name": `${data.seo.pagesSeo[1].pageTitle}`,
          "image": `${data.seo.pagesSeo[1].pageMedia.absolutePath}`
        }
      }]
    }
  ]
  return (
    <Layout>
      <SEO
        title={data.seo.pagesSeo[1].pageTitle}
        description={data.seo.pagesSeo[1].pageDescript}
        jsonld={jsonld}
        pageUrl={data.seo.pagesSeo[1].pageUrl}
        type='article'
        imageUrl={data.seo.pagesSeo[1].pageMedia.absolutePath}
      />
      <StyledWrap>
        <StyledBack />
        <StyledContents>
          <div className="pageTitle">
            <h1>{data.zoo.pageTitle}</h1>
            <p>{data.zoo.pageSubtitle}</p>
          </div>
          {data.zoo.textAndImage.map((panda, index) => (
            index % 2 === 0 ?
              <StyledBlock key={index}>
                <div className="blockSec">
                  <h2>{panda.numberTitle}</h2>
                  <p dangerouslySetInnerHTML={{ __html: panda.textsContents }} />
                </div>
                <Image fluid={panda.sectionMedia.childImageSharp.fluid} alt={panda.sectionMediaAlt} />
              </StyledBlock> :
              <StyledBlock key={index}>
                <Image fluid={panda.sectionMedia.childImageSharp.fluid} alt={panda.sectionMediaAlt} />
                <div className="blockSec">
                  <h2>{panda.numberTitle}</h2>
                  <p dangerouslySetInnerHTML={{ __html: panda.textsContents }} />
                </div>
              </StyledBlock>
          ))}
        </StyledContents>
      </StyledWrap>

    </Layout>
  )
}

export const query = graphql`
  {
    zoo:strapiForFirstVisit {
      pageTitle
      pageSubtitle
      textAndImage {
        id
        numberTitle
        textsContents
        sectionMediaAlt
        sectionMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    seo: strapiSeo {
      pagesSeo {
        pageDescript
        id
        pageTitle
        pageUrl
        pageMedia {
          absolutePath
        }
      }
      personName
      organizeName
      logoImage {
        absolutePath
      }
    }
  }
`

export default ForFirst
